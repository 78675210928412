
// https://github.com/gs-shop/vue-slick-carousel
import Vue, { PropType } from 'vue'
import { Photo } from '~/models/photos'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import FillImage from '~/components/FillImage.vue'
import { SlickCarouselSettings } from '~/models/slick-carousel'
import SaIcon from '~/components/_general/SaIcon.vue'
import SaImage from '~/components/_general/SaImage.vue'

const VueSlickCarousel = require('vue-slick-carousel')

// https://github.com/gs-shop/vue-slick-carousel
export default Vue.extend({
  name: 'PhotoCarousel',
  components: { SaImage, SaIcon, FillImage, VueSlickCarousel },
  props: {
    /** Tailwind height formatted h-#. Breakpoints are allowed **/
    heightClasses: {
      type: String,
      default: '',
      validator: (value: string) => {
        return value.includes('h-') || value === ''
      },
    },
    /** Tailwind padding formatted px-# **/
    spacing: {
      type: String,
      default: 'px-2',
      validator: (value: string) => {
        return value === '' || value.includes('px-')
      },
    },
    /** Tailwind border color formatted border-{color} **/
    border: {
      type: String,
      default: '',
      validator: (value: string) => {
        return value === '' || value.includes('border-')
      },
    },
    /** Milliseconds **/
    autoplaySpeed: {
      type: Number,
      default: 2500,
      validator: (value: number) => {
        return value >= 100
      },
    },
    /** Milliseconds **/
    scrollSpeed: {
      type: Number,
      default: 1000,
      validator: (value: number) => {
        return value >= 100
      },
    },
    /** Pixels **/
    imageHeight: {
      type: Number,
      default: undefined,
    },
    /** Pixels **/
    imageWidth: {
      type: Number,
      default: undefined,
    },
    photos: {
      type: Array as PropType<Photo[]>,
      default: () => {
        return []
      },
    },
    colorize: {
      type: String,
      default: '',
    },
    modal: {
      type: Boolean,
      default: true,
    },
    arrows: {
      type: Boolean,
    },
    /** This should be a Tailwind string demonstrating what color the gradient should fade to */
    fadeClasses: {
      type: String,
      default: '',
      validator: (value: string) => {
        return value === '' || value.includes('to-')
      },
    },
  },
  data() {
    return {
      modalPhoto: undefined as Photo | undefined,
      swiping: false,
      modalImageHeight: '',
      sliderSettings: {
        autoplaySpeed: this.autoplaySpeed,
        speed: this.scrollSpeed,
        adaptiveHeight: true,
        autoplay: true,
        arrows: this.arrows,
        touchThreshold: 20,
        swipeToSlide: true,
        variableWidth: true,
        centerMode: true,
      } as SlickCarouselSettings,
    }
  },
  computed: {
    colorizeStyles(): string {
      return this.colorize ? `background-color: ${this.colorize}` : ''
    },
    imageSizeStyles(): string {
      if (!this.imageHeight || !this.imageWidth) return ''
      return `height:${this.imageHeight}px;width:${this.imageWidth}px`
    },
  },
  methods: {
    closePhoto() {
      this.$modal.hide('slick-modal')
    },
    openPhoto(photo: Photo) {
      if (this.swiping) {
        this.swiping = false
        return
      }
      if (!this.modal) return
      this.modalPhoto = photo
      this.$modal.show('slick-modal')
    },
  },
})
