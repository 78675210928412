
import Vue from 'vue'
import { StripeCardElement, StripeCardElementOptions } from '@stripe/stripe-js'
import tailwindConfig from '~/tailwind.config.js'
import { StripePaymentMethod } from '~/models/stripe'

const resolveConfig = require('tailwindcss/resolveConfig')
const tailwind = resolveConfig(tailwindConfig).theme

export default Vue.extend({
  name: 'StripePaymentId',
  props: {
    name: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    street: {
      type: String,
      required: true,
    },
    city: {
      type: String,
      required: true,
    },
    state: {
      type: String,
      required: true,
    },
    zipcode: {
      type: String,
      required: true,
    },
    useTheme: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stripeCard: null as StripeCardElement | null,
      paymentMethod: undefined as StripePaymentMethod | undefined,
      complete: false,
      errorText: '',
    }
  },
  computed: {
    darkMode(): boolean {
      return this.useTheme && this.$colorMode.value === 'dark'
    },
  },
  watch: {
    disabled() {
      this.setup()
    },
  },
  mounted() {
    this.setup()
  },
  methods: {
    async setup() {
      const dark = this.darkMode
      const stripe = await this.$stripe()
      if (!stripe) return
      const fontSize = getComputedStyle(this.$refs.card as Element).fontSize
      const options = {
        disabled: this.disabled,
        hidePostalCode: true,
        iconStyle: 'solid',
        // https://stripe.com/docs/js/appendix/style
        style: {
          base: {
            iconColor: tailwind.colors.gray[dark ? '600' : '400'],
            color: tailwind.colors.gray[dark ? '100' : '800'],
            fontWeight: tailwind.fontWeight.normal,
            fontFamily: tailwind.fontFamily.sans.toString(),
            fontSize,
            fontSmoothing: 'antialiased',
            '::placeholder': {
              color: tailwind.colors.gray[dark ? '600' : '400'],
            },
          },
          complete: {
            iconColor: tailwind.colors.green[dark ? 'highlight' : 'default'],
            color: tailwind.colors.green[dark ? 'highlight' : 'default'],
          },
          invalid: {
            iconColor: tailwind.colors.red[dark ? 'highlight' : 'default'],
            color: tailwind.colors.red[dark ? 'highlight' : 'default'],
          },
        },
      } as StripeCardElementOptions

      this.stripeCard = stripe.elements().create('card', options)
      this.stripeCard.on('change', (event) => {
        this.complete = event.complete
        this.errorText = event.error ? event.error.message : ''
      })
      this.stripeCard.mount('#card-element')
    },
    async createPaymentMethod() {
      const stripe = await this.$stripe()
      if (!stripe) return
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: this.stripeCard as StripeCardElement,
        billing_details: {
          name: this.name,
          email: this.email,
          address: {
            city: this.city,
            line1: this.street,
            postal_code: this.zipcode,
            state: this.state,
          },
        },
      })
      this.paymentMethod = new StripePaymentMethod(result)
      return this.paymentMethod
    },
  },
})
