
import Vue, { PropType } from 'vue'
import { SaEvent } from '~/models/events'
import SaImage from '~/components/_general/SaImage.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import EventNav from '~/components/event/Nav.vue'
import ExplicitHeightElement from '~/components/_general/ExplicitHeightElement.vue'

export default Vue.extend({
  name: 'EventHeader',
  components: { ExplicitHeightElement, EventNav, SaIcon, SaImage },
  props: {
    event: {
      type: Object as PropType<SaEvent>,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      open: false,
    }
  },
  methods: {
    toggleOpen() {
      this.open = !this.open
    },
  },
})
