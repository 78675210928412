
import Vue, { PropType } from 'vue'
import { DateTimeFormatOptions } from 'vue-i18n'
import { SaEvent, SaEventSessions } from '~/models/events'
import HashAnchor from '~/components/_general/HashAnchor.vue'
import EventSectionHeader from '~/components/event/SectionHeader.vue'
import { localizeDateTime } from '~/assets/ts/utils/date'
import { DateFormat } from '~/assets/ts/enums'

interface SessionDay {
  weekday: string
  dayInt: number
  date: Date
  id: string
  abbreviation: string
  sessions: SaEventSessions[]
}

export default Vue.extend({
  name: 'EventSchedule',
  components: { EventSectionHeader, HashAnchor },
  props: {
    event: {
      type: Object as PropType<SaEvent>,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
    hashOffset: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentDayID: undefined as string | undefined,
      days: {} as Record<string, SessionDay>,
    }
  },
  computed: {
    currentDay(): SessionDay | undefined {
      if (!this.currentDayID || !this.days) return undefined
      return this.days[this.currentDayID]
    },
    currentDate(): string {
      const date = this.currentDay?.date
      return date ? localizeDateTime(date, DateFormat.MonthDayDate) : ''
    },
  },
  watch: {
    event() {
      this.setupDays()
    },
  },
  created() {
    this.setupDays()
  },
  methods: {
    sessionTime(session: SaEventSessions): string {
      const timeOptions = {
        hour: 'numeric',
        minute: '2-digit',
        timeZone: this.event.timezone,
      } as DateTimeFormatOptions
      const locale = this.$i18n.locale
      const startTime = session.dateBegin.toLocaleTimeString(
        locale,
        timeOptions
      )
      if (session.dateEnd) {
        const endTime = session.dateEnd.toLocaleTimeString(locale, timeOptions)
        return `${startTime} - ${endTime}`
      }
      return startTime
    },
    setupDays() {
      if (!this.event.sessions) return
      const days = {} as Record<string, SessionDay>
      for (let i = 0; i < this.event.sessions.length; i++) {
        const session = this.event.sessions[i] as SaEventSessions
        const date = session.dateBegin
        const dayInt = date.getDate()
        const mm = date.getMonth()
        const id = `${dayInt}${mm}`
        if (!days[id]) {
          const locale = this.$i18n.locale
          const cID = this.currentDayID
          const setCurrent = !cID || (days[cID] && days[cID].date > date)
          if (setCurrent) {
            this.currentDayID = id
          }
          days[id] = {
            dayInt,
            id,
            date,
            sessions: [session],
            weekday: date.toLocaleString(locale, {
              weekday: 'long',
              timeZone: this.event.timezone,
            }),
            abbreviation: date.toLocaleDateString(locale, {
              day: '2-digit',
              month: '2-digit',
              timeZone: this.event.timezone,
            }),
          }
        } else {
          days[id].sessions.push(session)
        }
      }
      this.days = days
    },
  },
})
