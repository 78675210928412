
import Vue, { PropType } from 'vue'
import { SaEvent } from '~/models/events'
import { Coupon } from '~/models/coupons'

export default Vue.extend({
  name: 'InputCoupon',
  props: {
    default: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: 'coupon',
    },
    useTheme: {
      type: Boolean,
      default: true,
    },
    event: {
      type: Object as PropType<SaEvent>,
      default: undefined,
    },
    delay: {
      type: Number,
      default: 500,
    },
    validText: {
      type: String,
      default: '',
    },
    invalidText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      coupon: undefined as Coupon | undefined,
      timeout: undefined as number | undefined,
      checking: false,
      value: this.default,
    }
  },
  computed: {
    valid(): boolean {
      return this.coupon !== undefined
    },
    statusColor(): string {
      const t = this.useTheme
      if (this.checking) {
        return t ? 'text-gray' : 'text-gray' // customize as necessary
      } else if (this.valid) {
        return t ? 'text-theme-green' : 'text-green' // customize as necessary
      } else {
        return t ? 'text-theme-red' : 'text-red'
      }
    },
    statusText(): string {
      if (!this.value) return ''
      if (this.checking) return this.$t('Checking coupon...').toString()
      if (this.valid) {
        return this.validText || this.$t('Valid coupon code!').toString()
      }
      return this.invalidText || this.$t('Invalid coupon code').toString()
    },
  },
  methods: {
    input() {
      this.$emit('input', this.value)
      this.debouncedCheckValid()
    },
    debouncedCheckValid() {
      this.checking = true
      this.coupon = undefined
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = window.setTimeout(() => {
        this.checkValid()
      }, this.delay)
    },
    async checkValid() {
      if (this.event) {
        const coupon = await this.$apiClient.getEventCouponInfo(
          this.event.id,
          this.value
        )
        this.coupon = coupon ? new Coupon(coupon) : undefined
      }
      this.$emit('valid', this.coupon)
      this.checking = false
    },
  },
})
