
import Vue, { PropType } from 'vue'
import { LatLngLiteral } from 'leaflet'
import MapMarker from '~/components/map/Marker.vue'
import SaIcon from '~/components/_general/SaIcon.vue'

export default Vue.extend({
  name: 'MapEventPin',
  components: { SaIcon, MapMarker },
  props: {
    latLng: {
      type: Object as PropType<LatLngLiteral>,
      required: true,
    },
  },
})
