
import Vue, { PropType } from 'vue'
import { SaEvent } from '~/models/events'
import { Photo } from '~/models/photos'
import PhotoCarousel from '~/components/_general/PhotoCarousel.vue'

export default Vue.extend({
  name: 'EventPhotos',
  components: { PhotoCarousel },
  props: {
    event: {
      type: Object as PropType<SaEvent>,
      required: true,
    },
  },
  computed: {
    photos(): Photo[] {
      return this.event && this.event.photos ? this.event.photos : []
    },
  },
})
