
import Vue, { PropType } from 'vue'
import { SaIconsType } from '~/assets/ts/types/icons'
import SaIcon from '~/components/_general/SaIcon.vue'
import ExplicitHeightElement from '~/components/_general/ExplicitHeightElement.vue'

export default Vue.extend({
  name: 'Accordion',
  components: { ExplicitHeightElement, SaIcon },
  props: {
    title: {
      type: String,
      default: undefined,
    },
    titleClasses: {
      type: String,
      default: 'font-semibold',
    },
    buttonClasses: {
      type: String,
      default: '',
    },
    textClasses: {
      type: String,
      default: '',
    },
    iconClasses: {
      type: String,
      default: '',
    },
    icon: {
      type: String as PropType<SaIconsType>,
      default: '',
    },
    prerender: {
      type: Boolean,
    },
    startOpen: {
      type: Boolean,
    },
    animate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpen: this.startOpen,
      update: false,
      hasBeenOpened: this.startOpen,
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.resize)
  },
  mounted() {
    window.addEventListener('resize', this.resize)
  },
  methods: {
    resize() {
      if (!this.isOpen) return
      this.update = !this.update
    },
    toggle() {
      this.hasBeenOpened = true
      this.isOpen = !this.isOpen
      this.$emit(this.isOpen ? 'opened' : 'closed')
      this.$nextTick(() => {
        this.update = !this.update
      })
    },
  },
})
