import { PaymentMethodResult } from '@stripe/stripe-js'

export class StripePaymentMethod {
  error?: string
  id?: string
  last4?: string
  brand?: string
  constructor(result: PaymentMethodResult) {
    this.error = result.error ? result.error.message : undefined
    const method = result.paymentMethod
    this.id = method ? method.id : undefined
    this.last4 = method && method.card ? method.card.last4 : undefined
    this.brand = method && method.card ? method.card.brand : undefined
  }
}

export interface StripeComponent extends Element {
  createPaymentMethod: () => Promise<StripePaymentMethod>
  paymentMethod: StripePaymentMethod | undefined
  complete: boolean
}
