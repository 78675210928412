
import Vue from 'vue'
import { SaEvent } from '~/models/events'
import { customSiteParams } from '~/assets/ts/utils/params'
import EventHeader from '~/components/event/Header.vue'
import EventHero from '~/components/event/Hero.vue'
import EventInfoBar from '~/components/event/InfoBar.vue'
import EventHighlightedText from '~/components/event/HighlightedText.vue'
import MarkdownElement from '~/components/markdown/Element.vue'
import EventVideo from '~/components/event/Video.vue'
import EventSpeakers from '~/components/event/Speakers.vue'
import EventPhotos from '~/components/event/Photos.vue'
import EventLocation from '~/components/event/Location.vue'
import EventSchedule from '~/components/event/Schedule.vue'
import EventNotes from '~/components/event/Notes.vue'
import EventCountdown from '~/components/event/Countdown.vue'
import EventFooter from '~/components/event/Footer.vue'
import EventRegistrationModal from '~/components/event/RegistrationModal.vue'
import Poller from '~/components/_general/Poller.vue'

export default Vue.extend({
  components: {
    Poller,
    EventRegistrationModal,
    EventFooter,
    EventCountdown,
    EventNotes,
    EventSchedule,
    EventLocation,
    EventPhotos,
    EventSpeakers,
    EventVideo,
    MarkdownElement,
    EventHighlightedText,
    EventInfoBar,
    EventHero,
    EventHeader,
  },
  layout: 'events',
  data() {
    return {
      hashOffset: 80,
    }
  },
  computed: {
    eventID(): string {
      return customSiteParams(this).id
    },
    event(): SaEvent {
      // https://stackoverflow.com/questions/49951504/how-can-i-load-a-component-based-on-the-domain-in-a-nuxt-vue-app
      // https://i18n.nuxtjs.org/different-domains/
      return new SaEvent(this.$store.getters['events/event'](this.eventID))
    },
  },
  methods: {
    register() {
      this.$modal.show('registration')
    },
    updateLiveWebcast() {
      this.$store.dispatch('webcasts/fetchWebcastsInProgress')
    },
  },
})
