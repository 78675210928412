
import Vue from 'vue'

export default Vue.extend({
  name: 'EventRegistrationTypeButton',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    cost: {
      type: Number,
      default: 0,
    },
    days: {
      type: Number,
      default: 1,
    },
    currency: {
      type: String,
      default: 'USD',
    },
    eachVerbiage: {
      type: String,
      required: true,
    },
  },
  computed: {
    displayCost(): string {
      return this.cost.toLocaleString(this.$i18n.locale, {
        style: 'currency',
        minimumFractionDigits: 0,
        currency: this.currency,
      })
    },
  },
})
