
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { SaEvent } from '~/models/events'
import { Sermon } from '~/models/sermon'
import HashAnchor from '~/components/_general/HashAnchor.vue'
import SaImage from '~/components/_general/SaImage.vue'
import EventRegisterButton from '~/components/event/RegisterButton.vue'
import Countdown from '~/components/_general/Countdown.vue'
import VideoPreview from '~/components/_general/VideoPreview.vue'

export default Vue.extend({
  name: 'EventHero',
  components: {
    VideoPreview,
    Countdown,
    EventRegisterButton,
    SaImage,
    HashAnchor,
  },
  props: {
    event: {
      type: Object as PropType<SaEvent>,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
    hashOffset: {
      type: Number,
      required: true,
    },
    endedText: {
      type: String as PropType<TranslateResult>,
      required: true,
    },
    inProgressText: {
      type: String as PropType<TranslateResult>,
      required: true,
    },
    blur: {
      type: Boolean,
    },
    colorize: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      videoContainerHeight: 400,
    }
  },
  computed: {
    hasVideo(): boolean {
      return !!this.heroVideoSermon?.hasVideo
    },
    previewPosterUrl(): string {
      return this.heroVideoSermon?.thumbnailURL(1920) ?? ''
    },
    heroVideoSermon(): Sermon | undefined {
      return this.event.heroSermon
    },
    heroVideoSermonUrl(): string {
      return this.heroVideoSermon?.videoPreviewURL ?? ''
    },
    heroImageUrl(): string {
      return this.event.headerImageUrl
    },
    heroGradient(): string {
      if (!this.colorize) return ''
      return 'linear-gradient(270deg, transparent, #000000bb)'
    },
    heroBackground(): string {
      const colorize = this.heroGradient
      const image = this.hasVideo ? '' : `url(${this.heroImageUrl})`
      const comma = colorize && image ? ', ' : ''
      return `background-image: ${colorize}${comma}${image}`
    },
  },
  mounted() {
    const container = this.$refs.videoContainer as HTMLElement
    this.resize({
      height: container ? container.clientHeight : 0,
    })
  },
  methods: {
    resize({ height }: Record<'height', number>) {
      this.videoContainerHeight = height
    },
  },
})
