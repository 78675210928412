
import Vue from 'vue'
import { SaEventNote } from '~/models/events'
import Accordion from '~/components/_general/Accordion.vue'
import MarkdownElement from '~/components/markdown/Element.vue'

export default Vue.extend({
  name: 'EventNoteList',
  components: { MarkdownElement, Accordion },
  props: {
    notes: {
      type: Array,
      default: [] as SaEventNote[],
    },
  },
})
