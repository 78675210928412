
import Vue from 'vue'
import SaImage from '~/components/_general/SaImage.vue'

export default Vue.extend({
  name: 'FillImage',
  components: { SaImage },
  props: {
    url: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
    yPadding: {
      type: Number,
      default: 0,
    },
    /** Tailwind round size class **/
    rounded: {
      type: String,
      default: '',
      validator(value: string) {
        return value === '' || value.includes('rounded')
      },
    },
  },
  data() {
    return {
      imageWidth: 0,
      imageHeight: 0,
      containerWidth: 0,
      containerHeight: 0,
    }
  },
  computed: {
    container(): HTMLElement | undefined {
      if (!this.$refs.container) return undefined
      return this.$refs.container as HTMLElement
    },
    aspectRatio(): number {
      return this.imageWidth / this.imageHeight
    },
    imageSizeStyle(): string {
      return `max-width: calc(${this.aspectRatio * 100}vh - ${this.yPadding}px)`
    },
  },
  mounted() {
    this.resize({
      width: this.container ? this.container.clientWidth : 0,
      height: this.container ? this.container.clientHeight : 0,
    })
  },
  methods: {
    resize({ width, height }: Record<'width' | 'height', number>) {
      this.containerWidth = width
      this.containerHeight = height
    },
    loaded(event: Event) {
      const img = event.target as HTMLImageElement
      this.imageWidth = img.naturalWidth
      this.imageHeight = img.naturalHeight
    },
  },
})
