
import Vue, { PropType } from 'vue'
import { SaEvent } from '~/models/events'
import { localizeDateRange } from '~/assets/ts/utils/date'
import EventInfoBarItem from '~/components/event/InfoBarItem.vue'

export default Vue.extend({
  name: 'EventInfoBar',
  components: { EventInfoBarItem },
  props: {
    event: {
      type: Object as PropType<SaEvent>,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  computed: {
    gridCount(): string {
      if (this.speakers && this.location) {
        return 'md:grid-cols-4'
      } else if (this.speakers || this.location) {
        return 'md:grid-cols-3'
      }
      return 'md:grid-cols-2'
    },
    speakers(): number {
      return this.event.speakers ? this.event.speakers.length : 0
    },
    location(): string | undefined {
      return this.event.shortAddress
    },
    dates(): string {
      const endDate = this.event.singleDayEvent ? undefined : this.event.dateEnd
      return localizeDateRange(
        this.event.dateBegin,
        endDate,
        this.event.timezone
      )
    },
  },
})
