
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { SaEvent } from '~/models/events'
import Countdown from '~/components/_general/Countdown.vue'
import EventRegisterButton from '~/components/event/RegisterButton.vue'

export default Vue.extend({
  name: 'EventCountdown',
  components: { EventRegisterButton, Countdown },
  props: {
    event: {
      type: Object as PropType<SaEvent>,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
    endedText: {
      type: String as PropType<TranslateResult>,
      required: true,
    },
    inProgressText: {
      type: String as PropType<TranslateResult>,
      required: true,
    },
  },
  computed: {
    ended(): boolean {
      return this.event.dateEndTimestamp < new Date().getTime()
    },
  },
})
