
import Vue from 'vue'
import SiteCopyrightText from '~/components/site/CopyrightText.vue'

export default Vue.extend({
  name: 'EventFooter',
  components: { SiteCopyrightText },
  props: {
    width: {
      type: String,
      required: true,
    },
  },
  computed: {
    year(): number {
      return new Date().getFullYear()
    },
  },
})
