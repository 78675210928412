
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { SaEvent } from '~/models/events'

export default Vue.extend({
  name: 'EventHighlightedText',
  props: {
    event: {
      type: Object as PropType<SaEvent>,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
    title: {
      type: String as PropType<TranslateResult>,
      required: true,
    },
    flip: {
      type: Boolean,
    },
  },
})
