
import Vue, { PropType } from 'vue'
import { SaEvent } from '~/models/events'
import SpeakerImage from '~/components/_general/SpeakerImage.vue'
import HashAnchor from '~/components/_general/HashAnchor.vue'
import EventSectionHeader from '~/components/event/SectionHeader.vue'

export default Vue.extend({
  name: 'EventSpeakers',
  components: { EventSectionHeader, HashAnchor, SpeakerImage },
  props: {
    event: {
      type: Object as PropType<SaEvent>,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
    hashOffset: {
      type: Number,
      required: true,
    },
  },
})
