
import Vue, { PropType } from 'vue'
import { SaEvent, SaEventNote } from '~/models/events'
import EventNoteList from '~/components/event/NoteList.vue'
import HashAnchor from '~/components/_general/HashAnchor.vue'
import EventSectionHeader from '~/components/event/SectionHeader.vue'

export default Vue.extend({
  name: 'EventNotes',
  components: { EventSectionHeader, HashAnchor, EventNoteList },
  props: {
    event: {
      type: Object as PropType<SaEvent>,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
    hashOffset: {
      type: Number,
      required: true,
    },
  },
  computed: {
    oddNotes(): SaEventNote[] {
      return this.getList(false)
    },
    evenNotes(): SaEventNote[] {
      return this.getList(true)
    },
  },
  methods: {
    getList(even: boolean): SaEventNote[] {
      const list = [] as SaEventNote[]
      if (!this.event.notes) return list
      for (let i = 0; i < this.event.notes.length; i++) {
        const isEven = i % 2 !== 0
        const n = this.event.notes[i] as SaEventNote
        if (even === isEven) {
          list.push(n)
        }
      }
      return list
    },
  },
})
