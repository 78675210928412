
import Vue, { PropType } from 'vue'
import { AutocompleteInputType } from '~/assets/ts/enums'
import InputAutocomplete from '~/components/Input/Autocomplete.vue'

export default Vue.extend({
  name: 'EventRegistrationInput',
  components: { InputAutocomplete },
  props: {
    default: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    required: {
      type: Boolean,
      default: true,
    },
    inputType: {
      type: Number as PropType<AutocompleteInputType>,
      required: true,
    },
  },
  data() {
    return {
      value: this.default,
    }
  },
})
