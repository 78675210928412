
import Vue, { PropType } from 'vue'

import {
  SaEvent,
  SaEventAdditionalAttendee,
  SaEventRegistrationResponse,
  SaEventTicket,
} from '~/models/events'
import { Broadcaster } from '~/models/broadcaster'
import { Coupon } from '~/models/coupons'
import { AutocompleteInputType } from '~/assets/ts/enums'
import { StripeComponent, StripePaymentMethod } from '~/models/stripe'
import { ToastError } from '~/assets/ts/utils/toast'
import { toCurrency } from '~/assets/ts/utils/math'
import { getApiError } from '~/assets/ts/utils/api'
import SaImage from '~/components/_general/SaImage.vue'
import EventRegistrationTypeButton from '~/components/event/RegistrationTypeButton.vue'
import EventRegistrationInput from '~/components/event/RegistrationInput.vue'
import Checkbox from '~/components/_general/Checkbox.vue'
import InputAutocomplete from '~/components/Input/Autocomplete.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import StripePaymentId from '~/components/StripePaymentId.vue'
import InputCoupon from '~/components/Input/Coupon.vue'
import EventRegistrationConfirmation from '~/components/event/RegistrationConfirmation.vue'
import EventRegistrationTotals from '~/components/event/RegistrationTotals.vue'
import SaLink from '~/components/_general/SaLink.vue'
import SpinnerButton from '~/components/_general/SpinnerButton.vue'

// https://git.sermonaudio.com/project-management/main/-/wikis/Discover-Tab-APIs#paying-when-registering-to-attend-an-event
export default Vue.extend({
  name: 'EventRegistrationModal',
  components: {
    SpinnerButton,
    SaLink,
    EventRegistrationTotals,
    EventRegistrationConfirmation,
    InputCoupon,
    StripePaymentId,
    SaIcon,
    InputAutocomplete,
    Checkbox,
    EventRegistrationInput,
    EventRegistrationTypeButton,
    SaImage,
  },
  props: {
    event: {
      type: Object as PropType<SaEvent>,
      required: true,
    },
  },
  data() {
    return {
      AutocompleteInputType,
      reset: false,
      family: false,
      registering: false,
      page: 1,
      couponCode: '',
      mealPlan: false,
      useRegistrationAddress: false,
      stripePaymentMethod: undefined as StripePaymentMethod | undefined,
      coupon: undefined as Coupon | undefined,
      ticket: undefined as SaEventTicket | undefined,
      email: '',
      firstName: '',
      lastName: '',
      street: '',
      city: '',
      state: '',
      zipcode: '',
      billingFirstName: '',
      billingLastName: '',
      billingStreet: '',
      billingCity: '',
      billingState: '',
      billingZipcode: '',
      phone: '',
      title: '',
      church: '',
      comments: '',
      familyMembers: [] as SaEventAdditionalAttendee[],
    }
  },
  computed: {
    actionButtonText(): string {
      switch (this.page) {
        case 1:
          return this.$t('Continue to Payment').toString()
        case 2:
          return this.$t('Complete Registration').toString()
        case 3:
        default:
          return this.$t('Register Another').toString()
      }
    },
    mealPlanCount(): number {
      let count = this.mealPlan ? 1 : 0
      for (let i = 0; i < this.familyMembers.length; i++) {
        count += this.familyMembers[i].mealPlan ? 1 : 0
      }
      return count
    },
    mealPlanText(): string {
      const c = this.currency(this.event.mealCost || 0)
      return `${this.$t('Include a Meal Plan?')} (+${c})`
    },
    subtotal(): number {
      return this.event.getSubtotal(
        this.family,
        this.freeEventCoupon,
        this.mealPlanCount
      )
    },
    freeEventCoupon(): boolean {
      return this.coupon !== undefined && this.coupon.FreeEvent
    },
    freeEvent(): boolean {
      return this.subtotal <= 0
    },
    hasMealPlan(): boolean {
      return this.event.mealCost !== undefined
    },
    maxRegistrations(): boolean {
      // 1 for the primary registrant
      return this.familyMembers.length + 1 >= this.event.maxGroupSize
    },
    billingInfo(): Record<any, any> {
      const s = this.useRegistrationAddress
      const name = s
        ? this.combineNames(this.firstName, this.lastName)
        : this.combineNames(this.billingFirstName, this.billingLastName)
      return {
        name,
        street: s ? this.street : this.billingStreet,
        city: s ? this.city : this.billingCity,
        state: s ? this.state : this.billingState,
        zipcode: s ? this.zipcode : this.billingZipcode,
      }
    },
    stripe(): StripeComponent {
      return this.$refs.stripe as StripeComponent
    },
    broadcasterImage(): string {
      if (!this.broadcaster) return ''
      return this.broadcaster.imageResizable(36) ?? ''
    },
    broadcaster(): Broadcaster {
      return this.event.broadcaster
    },
    broadcasterName(): string {
      return this.broadcaster.displayName
    },
    confirmationQueryString(): string {
      if (!this.ticket) return ''

      let qs = `?ticket=${this.ticket.id}&email=${this.email}&firstName=${this.firstName}&lastName=${this.lastName}`
      qs += `&street=${this.street}&city=${this.city}&state=${this.state}&zipcode=${this.zipcode}`

      if (this.mealPlan) qs += `&meal=true`
      if (this.title) qs += `&title=${this.title}`
      if (this.comments) qs += `&comments=${this.comments}`
      if (this.phone) qs += `&phone=${this.phone}`
      if (this.church) qs += `&church=${this.church}`
      if (this.coupon) qs += `&coupon=${this.couponCode}`
      if (this.familyMembers) qs += `&family=${this.familyMembersJson()}`

      return qs
    },
  },
  methods: {
    resetValues() {
      this.reset = true
      // https://stackoverflow.com/questions/35604987/is-there-a-proper-way-of-resetting-a-components-initial-data-in-vuejs
      // The above would be a cleaner way to do this, but it breaks the IDE (doesn't understand that these values exist)
      // so I opted not to use it. If we could find a way to improve IDE support
      // then it would definitely be better
      this.family = false
      this.registering = false
      this.page = 1
      this.couponCode = ''
      this.mealPlan = false
      this.useRegistrationAddress = false
      this.stripePaymentMethod = undefined
      this.coupon = undefined
      this.ticket = undefined
      this.email = ''
      this.firstName = ''
      this.lastName = ''
      this.street = ''
      this.city = ''
      this.state = ''
      this.zipcode = ''
      this.billingFirstName = ''
      this.billingLastName = ''
      this.billingStreet = ''
      this.billingCity = ''
      this.billingState = ''
      this.billingZipcode = ''
      this.phone = ''
      this.title = ''
      this.church = ''
      this.comments = ''
      this.familyMembers = []
      this.$nextTick(() => {
        this.reset = false
      })
    },
    async submitRegistration() {
      if (!this.stripe) return
      this.registering = true

      let values = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        street: this.street,
        city: this.city,
        state: this.state,
        zipcode: this.zipcode,
        churchAffiliation: this.church || undefined,
        title: this.title || undefined,
        phone: this.phone || undefined,
        comments: this.comments || undefined,
        currency: this.event.currency,
        // testCharge: true,
        paymentAmount: this.subtotal,
        couponID: this.coupon ? this.couponCode : undefined,
      } as Record<string, any>

      if (this.family) {
        values = {
          ...values,
          additionalAttendees: this.familyMembersJson(),
        }
      }

      if (!this.freeEvent) {
        this.stripePaymentMethod = await this.stripe.createPaymentMethod()
        if (this.stripePaymentMethod.error) {
          this.registrationFailed(this.stripePaymentMethod.error)
          return
        }
        values.paymentMethod = this.stripePaymentMethod.id
      }
      this.$axios
        .$post(`node/events/${this.event.id}/register_to_attend`, values)
        .then((resp: SaEventRegistrationResponse) => {
          this.ticket = new SaEventTicket(resp)
          this.registering = false
          this.nextPage()
        })
        .catch((err) => {
          this.registrationFailed(getApiError(err, this).message)
        })
    },
    registrationFailed(message: string) {
      this.registering = false
      ToastError(message)
    },
    formSubmitAction() {
      if (this.page === 1) {
        if (!this.family || this.familyMembers.length) {
          this.nextPage()
        } else {
          ToastError(
            this.$t(
              'Family Registration requires at least 1 additional registrant.'
            )
          )
        }
      } else if (this.page === 2) {
        this.submitRegistration()
      } else {
        this.resetValues()
      }
    },
    addFamilyMember() {
      if (this.maxRegistrations) return
      this.familyMembers.push(new SaEventAdditionalAttendee())
    },
    removeFamilyMember(index: number) {
      this.familyMembers.splice(index, 1)
    },
    familyMembersJson(): string {
      const list = [] as Record<string, any>[]
      const members = this.familyMembers
      for (let i = 0; i < members.length; i++) {
        const member = members[i]
        list.push({
          firstName: member.firstName,
          lastName: member.lastName,
          mealPlan: member.mealPlan,
        })
      }
      return JSON.stringify(list)
    },
    combineNames(first: string, last: string) {
      return `${first} ${last}`
    },
    currency(value: number | undefined): string {
      if (value === undefined) return ''
      return toCurrency(value, this.$i18n.locale, this.event.currency)
    },
    prevPage() {
      this.changePage(this.page - 1)
    },
    nextPage() {
      this.changePage(this.page + 1)
    },
    changePage(page: number) {
      this.page = page
      const form = this.$refs.registrationForm as HTMLFormElement
      if (!form) return
      form.scrollTo(0, 0)
      form.focus()
    },
  },
})
