
import Vue, { PropType } from 'vue'
import { SaEventAdditionalAttendee } from '~/models/events'

export default Vue.extend({
  name: 'EventRegistrationConfirmation',
  props: {
    ticket: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    street: {
      type: String,
      required: true,
    },
    city: {
      type: String,
      required: true,
    },
    state: {
      type: String,
      required: true,
    },
    zipcode: {
      type: String,
      required: true,
    },
    phone: {
      type: String,
      default: '',
    },
    church: {
      type: String,
      default: '',
    },
    comments: {
      type: String,
      default: '',
    },
    familyMembers: {
      type: Array as PropType<SaEventAdditionalAttendee[]>,
      default() {
        return []
      },
    },
  },
  computed: {
    familyList(): string {
      const members = this.familyMembers
      if (!members.length) return ''
      let list = ''
      for (let i = 0; i < members.length; i++) {
        if (i) list += ', '
        list += this.combineNames(members[i].firstName, members[i].lastName)
      }
      return list
    },
  },
  methods: {
    combineNames(first: string, last: string) {
      return `${first} ${last}`
    },
  },
})
