
import Vue, { PropType } from 'vue'
import { SaEvent } from '~/models/events'
import { toCurrency } from '~/assets/ts/utils/math'

export default Vue.extend({
  name: 'EventRegistrationTotals',
  props: {
    family: {
      type: Boolean,
      default: false,
    },
    coupon: {
      type: Boolean,
      default: false,
    },
    mealCount: {
      type: Number,
      default: 0,
    },
    event: {
      type: Object as PropType<SaEvent>,
      required: true,
    },
  },
  computed: {
    mealCost(): number {
      return this.event.getMealCost(this.mealCount)
    },
    subtotal(): number {
      return this.event.getSubtotal(this.family, this.coupon, this.mealCount)
    },
  },
  methods: {
    currency(value: number | undefined): string {
      if (value === undefined) return ''
      return toCurrency(value, this.$i18n.locale, this.event.currency)
    },
  },
})
