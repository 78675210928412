const plugin = require('tailwindcss/plugin')

function mediaMin(breakpoint) {
  return `(min-width: ${breakpoint})`
}
function mediaMax(breakpoint) {
  return `(max-width: ${parseInt(breakpoint) - 1}px)`
}

module.exports = plugin(function ({ addVariant, theme }) {
  const screens = theme('screens')
  addVariant('site-nav', '.site-nav-open &')
  addVariant('no-site-nav', '.site-nav-closed &')
  addVariant('light', 'html:not(.dark-mode) &')
  addVariant('hover', 'body:not(.mobile) &:hover')
  addVariant('disabled', '&:disabled')
  addVariant('ios', '@supports (-webkit-touch-callout: none) { & }')
  addVariant('not-ios', '@supports not (-webkit-touch-callout: none) { & }')
  addVariant('not-hover', '&:not(:hover)')
  addVariant('screen', '@media screen { & }')

  const screenKeys = Object.keys(screens)
  screenKeys.forEach((a, indexA) => {
    const start = mediaMin(screens[a])
    // looks like we don't actually need this as `max-` is a default with tailwind 3
    // also disabling it because overriding it like this makes max-[] not work
    // addVariant(`max-${a}`, `@media ${start}`)
    screenKeys.forEach((b, indexB) => {
      if (indexA < indexB) {
        const end = mediaMax(screens[b])
        addVariant(`${a}-${b}`, `@media ${start} and ${end}`)
      }
    })
  })

  const colSwapMin = screens.xm
  const colSwapMax = screens.lg
  const colSwapDjango = colSwapMin
  addVariant('2col', [
    `@media ${mediaMin(colSwapMin)} { .site-nav-closed & }`,
    `@media ${mediaMin(colSwapMax)} { & }`,
    `@media ${mediaMin(colSwapDjango)} { .django & }`,
  ])
  addVariant('1col', [
    `@media ${mediaMax(colSwapMin)} { .site-nav-closed & }`,
    `@media ${mediaMax(colSwapMax)} { .site-nav-open & }`,
    `@media ${mediaMax(colSwapDjango)} { .django & }`,
  ])
})
