
import Vue from 'vue'
import SaIcon from '~/components/_general/SaIcon.vue'

export default Vue.extend({
  name: 'Checkbox',
  components: { SaIcon },
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    useTheme: {
      type: Boolean,
      default: true,
    },
    partialChecked: {
      type: Boolean,
    },
    checked: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    boxClasses: {
      type: String,
      default: 'text-theme border-light',
    },
    autofocus: {
      type: Boolean,
    },
  },
  computed: {
    box(): string {
      if (!this.useTheme) return this.boxClasses
      return this.disabled
        ? 'text-gray bg-gray-300 border-gray-400 dark:text-gray-400 dark:bg-gray-600 dark:border-gray'
        : 'text-theme border-theme'
    },
    id(): string {
      return (Math.random() * 1000000).toString()
    },
    labelClasses(): string {
      if (this.checked && this.disabled) return 'checked disabled'
      if (this.checked) return 'checked'
      if (this.disabled) return 'disabled'
      return ''
    },
  },
  methods: {
    toggleChecked() {
      const input = this.$refs.input as HTMLElement
      input.click()
    },
    change(event: Event) {
      const target = event.target as HTMLInputElement
      this.$emit('change', target.checked)
    },
  },
})
