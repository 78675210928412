
import Vue, { PropType } from 'vue'
import { LatLngLiteral } from 'leaflet'
import { SaEvent } from '~/models/events'
import MapElement from '~/components/map/Element.vue'
import { StadiaMapStyle } from '~/assets/ts/enums'
import MarkdownElement from '~/components/markdown/Element.vue'
import MapEventPin from '~/components/map/EventPin.vue'
import HashAnchor from '~/components/_general/HashAnchor.vue'
import { toLatLng } from '~/models/map'
import SaIcon from '~/components/_general/SaIcon.vue'
import SaImage from '~/components/_general/SaImage.vue'

const TABLET_BREAKPOINT = 750
const MOBILE_BREAKPOINT = 640

export default Vue.extend({
  name: 'EventLocation',
  components: {
    SaImage,
    SaIcon,
    HashAnchor,
    MapEventPin,
    MarkdownElement,
    MapElement,
  },
  props: {
    event: {
      type: Object as PropType<SaEvent>,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
    hashOffset: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      zoom: 9,
      mapStyle: StadiaMapStyle.AlidadeSmoothLight,
      pageWidth: 0,
    }
  },
  computed: {
    image(): string | undefined {
      return this.event.locationImageUrl
    },
    desktop(): boolean {
      return this.pageWidth > TABLET_BREAKPOINT
    },
    mobile(): boolean {
      return this.pageWidth <= MOBILE_BREAKPOINT
    },
    offsetX(): number {
      return this.desktop ? 0.75 : 0
    },
    offsetY(): number {
      return this.desktop ? 0 : this.mobile ? 0.65 : 0.85
    },
    latLng(): LatLngLiteral {
      const lat = this.event.location?.lat || 0
      const lng = this.event.location?.lng || 0
      return toLatLng(lat + this.offsetY, lng + this.offsetX)
    },
    markerLatLng(): LatLngLiteral {
      const l = this.latLng
      return toLatLng(l.lat - this.offsetY, l.lng - this.offsetX)
    },
  },
  mounted() {
    this.updateWidth()
    window.addEventListener('resize', this.updateWidth)
  },
  destroyed() {
    window.removeEventListener('resize', this.updateWidth)
  },
  methods: {
    updateWidth() {
      if (this.$refs.container) {
        const el = (this.$refs.container as any).$el as any
        this.pageWidth = el.clientWidth
      }
    },
  },
})
