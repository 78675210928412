
import Vue from 'vue'
export default Vue.extend({
  name: 'HashAnchor',
  props: {
    offset: {
      type: Number,
      default: 0,
    },
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'div',
    },
  },
})
