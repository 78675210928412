
import Vue, { PropType } from 'vue'
import { AutocompleteInputType } from '~/assets/ts/enums'

// https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#attr-fe-autocomplete-additional-name
const AutocompleteInfo = {
  Name: {
    name: 'name',
    autocomplete: 'name',
  },
  FirstName: {
    name: 'first_name',
    autocomplete: 'given-name',
  },
  LastName: {
    name: 'last_name',
    autocomplete: 'family-name',
  },
  DisplayName: {
    name: 'display_name',
    autocomplete: 'nickname',
  },
  Username: {
    name: 'username',
    autocomplete: 'username',
  },
  Honorific: {
    name: 'honorific',
    autocomplete: 'honorific-prefix',
  },
  Email: {
    name: 'email',
    autocomplete: 'email',
  },
  Phone: {
    name: 'phone mobile',
    autocomplete: 'tel',
  },
  Company: {
    name: 'company',
    autocomplete: 'organization',
  },
  Street: {
    name: 'address',
    autocomplete: 'address street-address',
  },
  City: {
    name: 'city',
    autocomplete: 'city locality address-level2',
  },
  State: {
    name: 'region state',
    autocomplete: 'address-level1 region province',
  },
  Zipcode: {
    name: 'zip postal',
    autocomplete: 'postal-code',
  },
  Country: {
    name: 'country',
    autocomplete: 'country',
  },
  Password: {
    name: 'password',
    autocomplete: 'current-password',
  },
  NewPassword: {
    name: 'new-password',
    autocomplete: 'new-password',
  },
}

export default Vue.extend({
  name: 'InputAutocomplete',
  props: {
    inputType: {
      type: Number as PropType<AutocompleteInputType>,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
  },
  computed: {
    info(): Record<any, string> {
      switch (this.inputType) {
        case AutocompleteInputType.Name:
          return AutocompleteInfo.Name
        case AutocompleteInputType.FirstName:
          return AutocompleteInfo.FirstName
        case AutocompleteInputType.LastName:
          return AutocompleteInfo.LastName
        case AutocompleteInputType.DisplayName:
          return AutocompleteInfo.DisplayName
        case AutocompleteInputType.Username:
          return AutocompleteInfo.Username
        case AutocompleteInputType.Honorific:
          return AutocompleteInfo.Honorific
        case AutocompleteInputType.Email:
          return AutocompleteInfo.Email
        case AutocompleteInputType.Phone:
          return AutocompleteInfo.Phone
        case AutocompleteInputType.Company:
          return AutocompleteInfo.Company
        case AutocompleteInputType.Street:
          return AutocompleteInfo.Street
        case AutocompleteInputType.City:
          return AutocompleteInfo.City
        case AutocompleteInputType.State:
          return AutocompleteInfo.State
        case AutocompleteInputType.Zipcode:
          return AutocompleteInfo.Zipcode
        case AutocompleteInputType.Country:
          return AutocompleteInfo.Country
        case AutocompleteInputType.Password:
          return AutocompleteInfo.Password
        case AutocompleteInputType.NewPassword:
          return AutocompleteInfo.NewPassword
        default:
          return {
            name: '',
            autocomplete: '',
          }
      }
    },
  },
})
