
import Spinner from '~/components/_general/Spinner.vue'

export default {
  name: 'SpinnerButton',
  components: { Spinner },
  props: {
    waiting: { type: Boolean, default: false },
  },
}
